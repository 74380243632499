import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalculateIcon from '@mui/icons-material/Calculate';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import CL from 'country-flag-icons/react/3x2/CL'
import AR from 'country-flag-icons/react/3x2/AR'
import VE from 'country-flag-icons/react/3x2/VE'


export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Ind. Financieros" />
    </ListItemButton>
    {/* <ListItemButton>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Customers" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton> */}
  </React.Fragment>
);

export const SecondaryListItems = (onClick) => {

  const [countrySelected, setCountrySelected] = React.useState('')


return (
  <React.Fragment>
    {/* <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader> */}
    <ListItemButton onClick={ () => {onClick('/'); setCountrySelected('CL')} }> 
      <ListItemIcon >
        <CL title="Chile" style={{ width: 20, height: 16 }} />
      </ListItemIcon >
        <ListItemText >
          <span style={{ fontWeight: countrySelected === 'CL' ? 'bold' : 'normal' , color:  countrySelected === 'CL' ? '#3f51b5':''}}>Chile</span>
        </ListItemText>
     </ListItemButton>
    <ListItemButton  onClick={() => { onClick('/indicators-ar'); setCountrySelected('AR')}}>
      <ListItemIcon >
        <AR title="Argentina" style={{ width: 20, height: 16 }} />
      </ListItemIcon>
      <ListItemText >
        <span style={{ fontWeight: countrySelected === 'AR' ? 'bold' : 'normal' , color:  countrySelected === 'AR' ? '#3f51b5':''}}>Argentina</span>
      </ListItemText>
    </ListItemButton>
    <ListItemButton onClick={() => {onClick('/indicators-ve'); setCountrySelected('VE')}}>
      <ListItemIcon>
        <VE title="Venezuela" style={{ width: 20, height: 16 }} />
        {/* <CalendarViewWeekIcon  style={{ color:  countrySelected === 'VE' ? '#3f51b5':''}}/> */}
      </ListItemIcon>
      <ListItemText>
        <span style={{ fontWeight: countrySelected === 'VE' ? 'bold' : 'normal' , color:  countrySelected === 'VE' ? '#3f51b5':''}}>Venezuela</span>
      </ListItemText>
    </ListItemButton>
    <Divider sx={{ my: 1 }} />
    <ListItemButton onClick={() => {onClick('/calculator'); setCountrySelected('CAL')}}>
      <ListItemIcon>
        <CalculateIcon style={{ color:  countrySelected === 'CAL' ? '#3f51b5':''}}  />
      </ListItemIcon >
      <ListItemText>
        <span style={{ fontWeight: countrySelected === 'CAL' ? 'bold' : 'normal' , color:  countrySelected === 'CAL' ? '#3f51b5':''}}>Calculadora</span>
      </ListItemText>
    </ListItemButton>
    {/* <ListItemButton onClick={() => {onClick('/settings'); setCountrySelected('SETTINGS')}}>
      <ListItemIcon>
        <SettingsIcon style={{ color:  countrySelected === 'SETTINGS' ? '#3f51b5':''}}  />
      </ListItemIcon >
      <ListItemText>
        <span style={{ fontWeight: countrySelected === 'SETTINGS' ? 'bold' : 'normal' , color:  countrySelected === 'CAL' ? '#3f51b5':''}}>Ajustes</span>
      </ListItemText>
    </ListItemButton> */}
  </React.Fragment>
)};
