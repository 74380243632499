import React, { createContext, useContext, useState } from 'react';

const IndicatorContext = createContext(null);
 

export const IndicatorProvider = ({ children }) => {

    const [indicatorsVe, setIndicatorsVe] = useState(null);
    const [indicatorsCl, setIndicatorsCl] = useState(null);
    const [indicatorsAr, setIndicatorsAr] = useState(null);
    // const [currencies, setCurrencies] = useState(null)
    const [reload, setReload] = useState(false);
    const [message, setMessage] = useState(undefined);
    // const [fromCurrency, setFromCurrency] = useState({
    //     amount: 1,
    //     price: 1
    // });
    // const [toCurrency, setToCurrency] = useState({
    //     amount: 1,
    //     price: 1
    // });

    return (
        <IndicatorContext.Provider
            value={{
                indicatorsVe,
                setIndicatorsVe,
                indicatorsCl,
                setIndicatorsCl,
                indicatorsAr,
                setIndicatorsAr,
                reload,
                setReload,
                message,
                setMessage,
                // fromCurrency,
                // toCurrency,
            }}>
            {children}
        </IndicatorContext.Provider>
    );
};

export const useIndicatorContext = () => useContext(IndicatorContext);