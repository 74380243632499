export const formatHours = (date = new Date()) => {
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    return `${hours.length < 2 ? '0' + hours : hours }:${minutes.length < 2 ? '0' + minutes : minutes}`
}


export const formatDate = (date = new Date()) => {
    return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} - ${formatHours(date)}`;
}

export const getValue = (value = '') => {
  
    const formattedValue = value ? Number(value
                  .replace('.','')
                  .replace(',', '.')
                  .replace('%', '')
                ): value
    return formattedValue;            
}

export const isNumber = (str) => {
    return /^-?\d+(\.\d*)?$/.test(str);
}

export const toNumber = (num) => {
    const stringNumber = num + '';
    const numeroFormateado = stringNumber.replace(/\./g, '').replace(',', '.');
    const numero = parseFloat(numeroFormateado);
    return numero;
}

export const roundNumber = (num) => Math.round((num + Number.EPSILON) * 100) / 100
