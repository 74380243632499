import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import { Divider, MenuItem, Paper, InputBase } from "@mui/material";
import { isNumber } from "../../../util/util";

const CurrencyOption = ({
  currencies,
  value,
  setValue,
}) => {
  const onChangeInput = (event) => {
    if(!isNumber(event.target.value) && event.target.value !== ''){
      event.preventDefault();
      return;
    }
    setValue({
      ...value,
      quantity: event.target.value,
    })
  } 
  
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        border: "1px solid",
        borderColor: "#C4C4C4",
        boxShadow: "none",
      }}
    >
      <InputBase
        type="text"
        sx={{ width: "200px", paddingLeft: "8px", fontSize: 15 }}
        value={value.quantity}
        onChange={onChangeInput}
      />

      <Divider sx={{ height: 28, m: 0.9 }} orientation="vertical" />

      <Select
        sx={selectStyle}
        value={value.price}
        defaultValue={currencies[0].price}
        onChange={(event) => {
          setValue({
            ...value,
            price: event.target.value
          })
        }}
      >
        {currencies.map((currency, index) => (
          <MenuItem value={currency.price} key={index}>
            {currency.title}
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
};

export default CurrencyOption;

const selectStyle = { 
    fontSize: 14,
    width: '100%',
    height: '56px', 
    boxShadow: 'none', 
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
        border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: 0,
    },
}