export const config = {
    // URL_BACKEND: 'https://luro613g90.execute-api.us-east-1.amazonaws.com/dev/',
    // URL_BACKEND: 'http://localhost:3002',
    // URL_BACKEND: 'https://www.smartindicators.net',
    // URL_BACKEND: 'https://www.smartindicators.net',
    URL_BACKEND: 'https://3okuafc6ij.execute-api.us-east-1.amazonaws.com/dev',
    LAST_DATE_RECORD: 'LAST_RECORD',
    INDICAOTRS: 'INDICAOTRS',
}

