import { useEffect, useState } from "react";
import { useIndicatorContext } from "../../context/Context";
import EnhancedTable from '../../components/table/Table'
import DollarCard from "../../components/dollarCard/DollarCard";
import ConnectionError from "../error/ConnectionError";

const IndicatorsCl = () => {
    const { indicatorsCl } = useIndicatorContext()
    const [chileanCurrencies, setChileanCurrencies] = useState([])

    useEffect(() => {
        if(indicatorsCl && indicatorsCl?.tables.length > 0){
            setChileanCurrencies(
                indicatorsCl.tables.find( indicators => indicators.headers.some( indicator => indicator === 'MONEDAS' ) )
                .data.map(currency => ({
                    title: currency[0],
                    buy: currency[1],
                    variation: currency[2],
                })) 
            )
        }
    }, [indicatorsCl])

    useEffect(() => {
        console.log(chileanCurrencies)
    }, [])


    const tables = () => (
        <div style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'space-around',
            gap: '20px',
            marginTop:'20px',
            marginBottom: '20px'
        }}>
            {indicatorsCl.tables.map((indicator, index) => {
               return <EnhancedTable key={index} table={indicator}/>
            }
            )}
        </div>
    )

    console.log(indicatorsCl)
    const cards = () => {
        if(chileanCurrencies){
            return (
                <>
                <div style={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    justifyContent: 'space-around',
                    marginBottom: '20px'
                }}>
                    {chileanCurrencies.map((indicator, index)=>(
                        <DollarCard  
                            key={ index } 
                            buy={indicator.buy} 
                            title={indicator.title} 
                            readDate={ new Date(indicatorsCl.readDate._seconds*1000)} 
                            variation={indicator.variation}
                        />
                    ))}
                </div>
            </>

            )
        }
    }
    
    return indicatorsCl?.tables.length > 0 ? cards() : <ConnectionError />
}
 
export default IndicatorsCl;