import { useEffect } from "react";
import { useIndicatorContext } from "../../context/Context";
import DollarCard from "../../components/dollarCard/DollarCard";
import ConnectionError from "../error/ConnectionError";


const IndicatorsAr = () => {
    const { indicatorsAr } = useIndicatorContext()
    if(!indicatorsAr){
        return <ConnectionError />
    }

    return ( 
    <>
        <div style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            justifyContent: 'space-around',
            marginBottom: '20px'
        }}>
            {indicatorsAr.dollars.map((indicator, index)=>(
                <DollarCard  
                    key={ index } 
                    buy={indicator.buy} 
                    title={indicator.title} 
                    readDate={ new Date(indicatorsAr.readDate._seconds*1000)} 
                    variation={indicator.variation}
                    sale={indicator.sale}
                />
            ))}
        </div>
    </>
    )
}
 
export default IndicatorsAr;