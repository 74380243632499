import React from 'react';


const Ads = () => {


     React.useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

        catch (e) {

        }

    },[]);

    return (
        <>
            {/* <Adsense
            client="ca-pub-4109729103319929"
            slot="5781266133"
            style={{ width: 500, height: 300 }}
            format="auto"
            responsive="true"
            /> */}
            <ins className="adsbygoogle"
                style={{display:'block'}}
                data-ad-client="ca-pub-4109729103319929"
                data-ad-slot="5781266133"
                data-ad-format="auto"
                data-adtest="on"
                data-full-width-responsive="true">
            </ins>
{/* 
            <Adsense.Google
                client='ca-pub-4109729103319929'
                slot='2789169072'
                format='auto'
                /> */}

        <ins className="adsbygoogle"
            style={{display:'block'}}
            data-ad-client="ca-pub-4109729103319929"
            data-ad-slot="2789169072"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>

    </>  

    )

}

export default Ads;




// import React from 'react';
// import { Helmet } from 'react-helmet';

   

// const Ads = () => {


    // React.useEffect(() => {

    //     try {
    //         (window.adsbygoogle = window.adsbygoogle || []).push({});
    //     }

    //     catch (e) {

    //     }

    // },[]);

//   return (
//     <div>
//       <Helmet>
//         <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4109729103319929" crossorigin="anonymous"></script>
//       </Helmet>
//       <ins className="adsbygoogle"
//            style={{ display: 'block' }}
//            data-ad-client="ca-pub-4109729103319929"
//            data-ad-slot="5781266133"
//            data-ad-format="auto"
//            data-full-width-responsive="true"></ins>
//     </div>
//   );
// };

//  export default Ads;








