
import { useEffect, useRef, useState } from 'react';
import { roundNumber } from '../../../util/util';
import CurrencyOption from './CurrencyOption';


const Conversor = ({currencies}) => {

    const [localValue, setLocalValue] = useState(undefined);
    const [foreignValue, setForeigValue] = useState(undefined);
    // const activateChange = useRef(true) 
    

    useEffect( () => {
        if(currencies.length > 0){
            setLocalValue({
                quantity: currencies[1][0].price,
                price: 1
            })
            setForeigValue({
                quantity: 1,
                price:  currencies[1][0].price
            });

        }

    }, [currencies])


    const onHandleForeignChange = (value) => {
        if(value){
            setForeigValue(value)
            setLocalValue({
                ...localValue,
                quantity: roundNumber(value.price * value.quantity)
            })
        }
    }


    const onHandleLocalChange = (value) => {
        if(value){
            setLocalValue(value)
            setForeigValue({
                ...foreignValue,
                quantity: roundNumber(value.quantity / foreignValue.price )
            })
        }
    }

    return  (
        <>
            {foreignValue && <CurrencyOption
                currencies={currencies[1]}
                value={foreignValue}
                setValue={onHandleForeignChange}
            />}
            {localValue && <CurrencyOption 
                currencies={currencies[0]}
                value={localValue}
                setValue={onHandleLocalChange}
            />}
        </> 
    )
        
    
}
 
export default Conversor;