import { Image } from '@mui/icons-material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Box } from '@mui/material';
import disconnectError from '../../assets/disconnect-plug-icon.png'
const ConnectionError = () => {
    return ( 
        <div style={{ display: 'flex', justifyContent: 'center', height: '100%', padding: '10px'}}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <p style={{ textAlign: 'center' }}>
                {/* <SentimentVeryDissatisfiedIcon sx={{ fontSize: 90, color: '#ff9800' }} /> */}
                <Box
                    component="img"
                    sx={{
                        height: 233,
                        width: 'auto',
                        maxHeight: { xs: 233, md: 167 },
                        maxWidth: { xs: 350, md: 250 },
                    }}
                    alt="Error"
                    src={disconnectError}
                />
            </p>
            <p style={{ color:'#3f51b5', fontSize: '22px', textAlign: 'center' }}>   
                Estamos presentando problemas para recuperar la información
            </p>
            <p style={{ textAlign: 'center', color:'#3f51b5', fontSize: '22px' }}>
                Intentalo más tarde por favor
            </p>  
            </div>
        </div>
    );
}
 
export default ConnectionError;