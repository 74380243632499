import React from 'react';
import './Spinner.css'

const Spinner = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%', 
            flex: 1,
            height: '100%'
        }}>
            <span className='loader' />
        </div> 
    );
}
 
export default Spinner; 