
import { useIndicatorContext } from "../../context/Context";
import DollarCard from "../../components/dollarCard/DollarCard";
import ConnectionError from "../error/ConnectionError";

const IndicatorsVe = () => {
    const { indicatorsVe } = useIndicatorContext()
 
    if(!indicatorsVe){
        return <ConnectionError />
    }

    return ( 
        <>
            <div style={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                justifyContent: 'space-around',
                marginBottom: '20px'
            }}>
                {indicatorsVe.dollars.map((indicator, index)=>(
                    <DollarCard  key={ index } buy={indicator.buy} title={indicator.title} readDate={ new Date(indicatorsVe.readDate._seconds*1000)} />
                ))}
            </div>
        </>
    )
}
 
export default IndicatorsVe;