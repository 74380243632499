import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { formatDate } from "../../util/util";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const style = {
  price: { fontSize: '14px', fontWeight: 'bold'  },
  titlePrice: { fontSize: '12px', fontWeight: 'lighter' },
  date: { display: 'flex',  fontSize: '10px', alignItems:'end', fontWeight: 'lighter' },
}

const DollarCard = ({ title, buy, sale, variation, readDate }) => {


  const getIconVariation = (variationString='') => {
    
    const variation = Number(variationString.replace('%', '').replace(',','.' ));

    if(variation === 0){
      return (
        <Box display='flex' alignItems='center' color='#3f51b5' sx={{ fontWeight: 'bold'}}>
          <DragHandleIcon /> {`${variation}%`}
        </Box>
      )
    }
    if (variation > 0){
      return (
        <Box display='flex' alignItems='center' color='#009688' sx={{ fontWeight: 'bold'}}>
          <KeyboardDoubleArrowUpIcon /> {`${variation}%`}
        </Box>
      )
    }
    if (variation < 0){
      return (
        <Box display='flex' alignItems='center' color='#f44336' sx={{ fontWeight: 'bold'}}>
          <KeyboardDoubleArrowDownIcon /> {`${variation}%`}
        </Box>
      )
    }

  }

  const content = ({ title, buy, sale, variation, readDate  }) => {

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* first row */}
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Box>
            <Typography variant="h7" component="div" color='#069' sx={{fontWeight: 'bold'}} >
              { title }
            </Typography> 
            </Box>
            <Box>
              { variation && getIconVariation(variation) }
            </Box>
          </Box>

        {/* second row */}
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Box display='flex' flexDirection='column' justifyContent='space-between' >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <span style={ style.price }> { buy } </span>
                <span style={ style.price }> { sale ? sale : '' } </span>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <span style={ style.titlePrice }> Compra </span>
                <span style={ style.titlePrice }> { sale ? 'Venta' : '' } </span>
              </Box>
            </Box>
            <Box sx={ style.date }>
              { formatDate(readDate) }
            </Box>
          </Box>
      </Box>
    )
  }
    
  return (
    <Card sx={{ minWidth: 275, marginTop: "20px" }}>
      <CardContent>
        {content({ title, buy, sale, variation, readDate })}
      </CardContent>
    </Card>
  );
};

export default DollarCard;
