import React, {useEffect, useState} from 'react';
import { styled} from '@mui/material/styles';

import MuiDrawer from '@mui/material/Drawer';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ReplayIcon from '@mui/icons-material/Replay';
import { mainListItems, SecondaryListItems } from '../../dashboard/listItems';
import { useNavigate } from 'react-router-dom';
import { useIndicatorContext } from '../../context/Context';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const Layout = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const {setReload } = useIndicatorContext();

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(()=>{
      setOpen(width >= 768)
    }, [width])

    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const toggleDrawer = () => {
        setOpen(!open);
      };

    const [menuLabel, setMenuLabel] = React.useState('Home');

    const getLabelMenu = (path) => {

      const barTitle = {
        '/': 'Chile',
        '/indicators-ar': 'Argentina',
        '/indicators-ve': 'Venezuela',
        '/calculator': 'Calculadora',
        '/settings': 'Ajustes',
      }
      setMenuLabel(barTitle[path] || 'Home')

    }

    
    const onHandleNavigate = (path) => {
      console.log(path)
      getLabelMenu(path);
      navigate(path)

    }  

    return (
        <>
            <AppBar position="absolute" open={open} 
                sx={{ 
                  backgroundImage: 'linear-gradient(135deg, rgb(143, 164, 203) 0%, rgb(74, 81, 131) 100%)',
                  // boxShadow: '-7px 0px 14px black'
                }}>
                <Toolbar
                    sx={{
                        pr: '24px',
                        background:'rgb(75, 87, 130)', // keep right padding when drawer closed,
                        // boxShadow: '-7px 0px 14px black'
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {menuLabel}
                    </Typography>
                    <IconButton 
                      onClick={()=>{ setReload(true) }}
                      color="inherit"
                    >
                        {/* <Badge badgeContent={4} color="secondary"> */}
                            <ReplayIcon />
                        {/* </Badge> */}
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}
              sx={{ boxShadow: '-7px 0px 14px black' }}
            >
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer}>
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component="nav">
                {mainListItems}
                <Divider sx={{ my: 1 }} />
                {SecondaryListItems(onHandleNavigate)}
                {/* <Divider sx={{ my: 1 }} />
                {FooterList(onHandleNavigate) } */}
              </List>
          </Drawer>
        </>
    )
}

export default Layout;
