import * as React from 'react';
import SnackbarMaterial from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = ({
    open = 'false',
    setOpen,
    type = 'info',
    message = '',
    autoHideDuration = 3000,
}) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(undefined);
    };

    return ( 
        <SnackbarMaterial open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </SnackbarMaterial>
     );
}
 
export default Snackbar;