import { useEffect } from 'react';

function ServiceWorkerWrapper() {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/serviceWorker.js');
      });
    }
  }, []);

  return null;
}

export default ServiceWorkerWrapper;
