import axios from "axios";
import { config } from "../config/constans";

const options = {method: 'GET', url: 'http://192.168.1.155:3000/indicators-ar'};



const getIndicators = async () => {
 //const indicators = await axios.request(options);
 const indicators = await axios.get(`${config.URL_BACKEND}/indicators`);
 return indicators.data;
}

export {
    getIndicators
};