import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ maxWidth: '800px', margin: '20px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: '1.6' }}>
      <h1 style={{ color: '#0056b3' }}>Política de Privacidad</h1>

      <h2 style={{ color: '#0056b3' }}>Introducción</h2>
      <p>Tu privacidad es importante para nosotros. Esta Política de Privacidad explica cómo recopilamos, usamos, compartimos y protegemos tu información cuando utilizas nuestra aplicación para mostrar el precio del dólar en Venezuela, Argentina y Chile, y la calculadora de conversión de moneda.</p>

      <h2 style={{ color: '#0056b3' }}>Información que Recopilamos</h2>
      <p><strong>Información Personal:</strong> No recopilamos información personal como nombre, correo electrónico, etc.</p>
      <p><strong>Información No Personal:</strong> Podemos recopilar datos sobre el uso de la aplicación, como la frecuencia de uso, conversiones realizadas, y otros datos relacionados con la interacción del usuario con la aplicación.</p>

      <h2 style={{ color: '#0056b3' }}>Uso de la Información</h2>
      <p>Utilizamos la información recopilada para:</p>
      <ul>
        <li>Mejorar la experiencia del usuario.</li>
        <li>Analizar el uso de la aplicación para hacer mejoras y optimizaciones.</li>
      </ul>

      <h2 style={{ color: '#0056b3' }}>Compartir Información</h2>
      <p>No compartimos tu información con terceros, excepto en las siguientes circunstancias:</p>
      <ul>
        <li>Para cumplir con requisitos legales.</li>
        <li>Con proveedores de servicios que nos ayudan a operar nuestra aplicación (estos proveedores están obligados a proteger tu información).</li>
      </ul>

      <h2 style={{ color: '#0056b3' }}>Seguridad de los Datos</h2>
      <p>Implementamos medidas de seguridad para proteger tu información contra acceso no autorizado, alteración y destrucción. Sin embargo, ten en cuenta que ninguna medida de seguridad es completamente infalible.</p>

      <h2 style={{ color: '#0056b3' }}>Derechos de los Usuarios</h2>
      <p>Tienes derecho a:</p>
      <ul>
        <li>Acceder a los datos que hemos recopilado sobre ti.</li>
        <li>Solicitar la rectificación o eliminación de tus datos.</li>
      </ul>
      <p>Para ejercer estos derechos, contáctanos a través de la información de contacto proporcionada.</p>

      <h2 style={{ color: '#0056b3' }}>Cambios en la Política de Privacidad</h2>
      <p>Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Te notificaremos sobre cualquier cambio publicando la nueva política en esta página. Última actualización: <strong>19/07/2024</strong>.</p>

      <h2 style={{ color: '#0056b3' }}>Contacto</h2>
      <p>Si tienes preguntas o inquietudes sobre nuestra política de privacidad, contáctanos en: <strong>financialmarkets.indicators@gmail.com</strong>.</p>
    </div>
  );
};

export default PrivacyPolicy;
