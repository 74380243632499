import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Layout from '../layout/Layout';
import IndicatorsCl from '../indicatorCl/IndicatorCl';
import IndicatorsVe from '../indicatorsVe/IndicatorsVe';
import IndicatorsAr from '../indicatorsAr/IndicatorsAr';
import { Routes, Route, Link, BrowserRouter as Router } from 'react-router-dom';
import Calculator from '../calculator/Calculator';
import { useIndicatorContext } from '../../context/Context';
import { getIndicators } from '../../services/indicator.service';
import { config } from '../../config/constans';
import Spinner from '../../components/loading/Spinner';
import Snackbar from '../../components/snackBar/SnackBar';
import { loadMockData, mockError } from './MockData';
import Ads from '../../adsense/Adsense';
import Settings from '../settings/Settings';
import PrivacyPolicy from '../policies/PrivacyPolicy';

const defaultTheme = createTheme();



const Home = () => {

  const { 
    setIndicatorsAr, 
    setIndicatorsCl, 
    setIndicatorsVe, 
    reload, 
    setReload,
    message,
    setMessage,
  } = useIndicatorContext()
  const [loading, setLoading] = useState(false);

  const shouldReload = () => {
    const storeDate = localStorage.getItem(config.LAST_DATE_RECORD);
    if(!storeDate){
      return true;
    }
    const lastDateRecord = new Date(storeDate);
    const indicators = JSON.parse(localStorage.getItem(config.INDICAOTRS));
    
    if(indicators.dataAr && indicators.dataCl && indicators.dataVe) {
        const currentDate = new Date()
        const milisecDiff = currentDate - lastDateRecord;
        const hoursDiff = milisecDiff / (1000 * 60 * 60);
        return hoursDiff > 8
    }else {
      return true;
    }
  }  


  const loadData = async() => {
    try{
      setLoading(true);
      if(shouldReload()){
          const resp =  await getIndicators();
          setIndicatorsAr(resp.dataAr);
          setIndicatorsCl(resp.dataCl);
          setIndicatorsVe(resp.dataVe);
          localStorage.setItem(config.LAST_DATE_RECORD, new Date())
          localStorage.setItem(config.INDICAOTRS, JSON.stringify(resp))
        }else {
          const indicators = JSON.parse(localStorage.getItem(config.INDICAOTRS))
          setIndicatorsAr(indicators.dataAr);
          setIndicatorsCl(indicators.dataCl);
          setIndicatorsVe(indicators.dataVe);
        }
      setLoading(false);
      setMessage({
        text: '¡Información actualizada!',
        type: 'info',
      });   

    }catch(err){
      setLoading(false);
      setMessage({
        text: 'Ha ocurrido un error',
        type: 'error',
      });
    }
  }


  useEffect(()=>{
    // loadMockData(setIndicatorsAr, setIndicatorsCl, setIndicatorsVe);
    // mockError(setIndicatorsAr, setIndicatorsCl, setIndicatorsVe)
    loadData();
}, [])


  useEffect(() => {
    reload && loadData();
    setReload(false);
  }, [reload])


  return ( 
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Layout/>   
        <Box
          component="main"
          sx={{
            // backgroundImage: 'linear-gradient(135deg, rgb(143, 164, 203) 0%, rgb(74, 81, 131) 100%)',
            backgroundColor: '#e3ecfc',
            // backgroundColor: 'rgba(247, 247, 247,0.5)',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        <Toolbar />
        {loading ? <Spinner/> :
            <Routes>
                <Route index path='/' element={< IndicatorsCl/>} />
                <Route path="/" component={ Home }> 
                    <Route path='indicators-ve' element={ < IndicatorsVe /> } />
                    <Route path='indicators-ar' element={ < IndicatorsAr /> } />
                    <Route path='calculator' element={ < Calculator /> } />
                    <Route path='policies' element={ < PrivacyPolicy /> } />
                    {/* <Route path='settings' element={ < Settings /> } /> */}
                </Route>
            </Routes>
        }  
        </Box>
      </Box>
      <Ads/>
      <Box 
        flexDirection='row'
        display='flex' 
        flex={1} 
        justifyContent='center'
        sx={{ width: '100%' }}
      >
        <Snackbar 
          open={message !== undefined}
          message={message?.text}
          autoHideDuration={6000}
          onClose={() =>  setMessage(undefined)}
          type={message?.type}
          setOpen={setMessage}
        /> 
      </Box>
    </ThemeProvider>

  );
}
 
export default Home;