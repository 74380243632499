import React from "react";
// import { BrowserRouter as Router, Route } from 'react-router-dom';
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import Home from "./pages/home/Home";
import { Routes, Route, Link, BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div className="App">
      {/* <ServiceWorkerWrapper /> */}
      <Router>
        <Home />
      </Router>
    </div>
  );
}

export default App;
