import  React, { useEffect, useState }  from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useIndicatorContext } from '../../context/Context';
import { FormControl, Paper } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Conversor from './Conversor/Conversor';
import { toNumber } from '../../util/util';


const Calculator = () => {
    const { indicatorsCl, indicatorsVe, indicatorsAr } = useIndicatorContext()  

    // const countries = ['Argentina', 'Chile', 'Venezuela'];
    const [countries, setCountries] = useState([]);
    const [ selectedCountry, setSelectedCountry ] = useState('')
    const [currencies, setCurrencies]  = useState([[],[]])
   
       
    const getCurrenciesVe = () => {
        const foreignCurrencies = indicatorsVe.dollars.map(currency => ({
            ...currency,
            price: Number(currency.buy),
        }));
        return [[{ title: 'Bs', buy: '1', price: 1 }], foreignCurrencies];
    }


    const getCurrenciesAr = () => {
        const foreignCurrencies = indicatorsAr.dollars.map(
            currency => ({
                title: currency.title, 
                buy: currency.buy,
                price: toNumber(currency.buy),
            })
        )
        return [[{ title: 'ARG', buy: '1', price: 1 }], foreignCurrencies ];
    }

    const getCurrenciesCl = () => {
        if(!indicatorsCl) return [[], []]
        const foreignCurrencies = indicatorsCl.tables
            .find(indicator=> indicator.headers[0]==='MONEDAS')
            .data.map(arrayCurrency => ({ 
                title: arrayCurrency[0], 
                buy: arrayCurrency[1],
                price: toNumber(arrayCurrency[1]) 
            }))
        return [[{title: 'CLP', buy: '1', price: 1}], foreignCurrencies]
    }


    useEffect(()=>{
        console.log({indicatorsVe,
            indicatorsAr,
            indicatorsCl})
        // if(!indicatorsVe || !indicatorsAr || !indicatorsCl){
        //     return
        // }
        const countriesTemp = []
        if(indicatorsAr){
            countriesTemp.push('Argentina')
        }

        if(indicatorsCl?.tables?.length > 0){
            countriesTemp.push('Chile')
        }

        if(indicatorsVe){
            countriesTemp.push('Venezuela')
        }

        setCountries(countriesTemp)

        const defaultCurrencies = [[{title: 'Selecciona un pais', buy: ''}],[{title: 'Selecciona un pais', buy: '1'}]]
        const currenciesFunc = {
            'Venezuela': getCurrenciesVe(),
            'Argentina': getCurrenciesAr,
            'Chile': getCurrenciesCl,
        }

        let selectedCurrency  = currenciesFunc[selectedCountry];
        selectedCurrency = selectedCurrency ?? defaultCurrencies;
        setCurrencies(selectedCurrency || defaultCurrencies)

    }, [selectedCountry, indicatorsVe, indicatorsAr, indicatorsCl ])


    if(currencies?.length === 0 && indicatorsVe ){
        return <></>
    }
    
    return ( 
    <Paper style={{ margin: '16px'}}>
        <Box sx={{
            // width: '100%',
            // minWidth: 120, 
            display: 'flex', 
            gap: '20px',  
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: '25px'
        }}>
            <FormControl fullWidth>
                <InputLabel 
                    // variant="standard"
                    // htmlFor="country-select" 
                    id="select-country-label"
                >
                    País
                </InputLabel>
                <Select sx={{ width: '100%'} }
                    // name='country-select"'
                    onChange={(e)=>setSelectedCountry(e.target.value)}
                    id="select-country"
                    label="Pais *"
                >
                    {countries.map((country, index)=> (
                        <MenuItem  key={index} value={country}>{country}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {selectedCountry &&  <Conversor currencies={currencies}/>}            
        </Box>
    </Paper> 
    );
}
 
export default Calculator;




